// Fonts
$font-default: 'Gotham', sans-serif;
$font-primary: 'Gotham', sans-serif;
$font-secondary: 'Gotham', sans-serif;

// Colors
$default: #6c757d;
$primary: #232c56;
$secondary: #56a8da;

// General Colors
$red: #dc3545;
$green: #28a745;
$blue: #007bff;
$yellow: #ffc107;
$orange: #fd7e14;
$brown: #7b4f2c;
$purple: #6f42c1;
$pink: #e83e8c;
$white: #fff;
$gray: #6c757d;
$black: #343a40;
$teal: #20c997;
$cyan: #17a2b8;
