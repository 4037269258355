/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(darken($secondary, 10), 0.8);
  overflow: hidden;
  padding: 0;
  margin-bottom: -120px;
  position: relative;
  z-index: 1;


  .carousel-item {
    width: 100%;
    height: 90vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center center;

  }

  .carousel-item::before {
    content: '';
    //background-color: rgba($white, 0.2);
    position: absolute;
    top: 0;
    right:0;
    left: 0;
    bottom: 0;
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 120px;
  }

  .carousel-content {
    text-align: center;
    padding: 2.5rem;
    border-radius: 0.4rem;
    //background:  rgb(0 0 0 / 58%);
    top:0;
    position: absolute;
    filter: drop-shadow(0px 1px 8px black);
    
  }

  h2 {
    color: white;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
    span {
      color: $secondary;
    }
  }

  p {
    width: 80%;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: white;
  }
  
  .carousel-inner {
    .carousel-item {
      transition-property: opacity;
      background-position:  center;
    }

    .carousel-item,
    .active.carousel-item-start,
    .active.carousel-item-end {
      opacity: 0;
    }

    .active,
    .carousel-item-next.carousel-item-start,
    .carousel-item-prev.carousel-item-end {
      opacity: 1;
      transition: 0.5s;
    }

    .carousel-item-next,
    .carousel-item-prev,
    .active.carousel-item-start,
    .active.carousel-item-end {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  .carousel-control-prev, .carousel-control-next {
    width: 10%;
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
    color: $secondary;
  }

  .carousel-indicators li {
    cursor: pointer;
    background: $primary;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-bottom: 180px;
    opacity: .2;
    &.active {
      opacity: 1;
    }
  }

  .btn-get-started {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid $secondary;
    background: $secondary;
    &:hover {
      background: transparent;
      color: $secondary;
      text-decoration: none;
    }
  }

  @media (max-height: 768px), (max-width: 1200px) {
    margin-bottom: -80px;
    .carousel-container {
      padding-bottom: 0;
    }
    .carousel-indicators li {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1050px) {
    height: 55vh;
    .carousel-item {
      height: 55vh;
    }
  } 

  @media (max-width: 850px) {
    height: 50vh;
    .carousel-item {
      height: 50vh;
    }
  } 

  @media (max-width: 768px) {
    height: 45vh;
    .carousel-item {
      height: 45vh;
    }
  }

  @media (max-width: 550px) {
    height: 30vh;
    .carousel-item {
      height: 30vh;
    }
  }

  





  @media (min-width: 1024px) {
    p {
      width: 50%;
    }
    .carousel-control-prev, .carousel-control-next {
      width: 5%;
    }
  }

}
