/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: $secondary;
  padding: 10px 0;
  font-size: 14px;

  .contact-info {

    i {
      font-style: normal;
      color: white;

      a, span {
        padding-left: 5px;
        color: #fff;

        @media (max-width: 650px) {
            font-size: 0.7rem;
        }
        
      }

      a {
        line-height: 0;
        transition: 0.3s;
        &:hover {
          color: $primary;
        }
      }

    }
  }

  .social-links {
    a {
      color: white;
      line-height: 0;
      transition: 0.3s;
      margin-left: 10px;
      &:hover {
        color: white;
      }
    }
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: $primary;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

  .logo {

    h1 {
      font-size: 26px;
      padding: 0 0 0 8px;
      margin: 11px 0;
      line-height: 1;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: $font-secondary;
      border-left: 8px solid $primary;
    }

    h1 a, h1 a:hover {
      color: $secondary;
      text-decoration: none;
    }

    img {
      margin: 0;

      max-height: 140px !important;
      width: 100%;
      padding: 0;
      z-index: 999;
      position: relative;
      border-radius: 6rem;
      padding: 0.4rem 1.8rem;
      background: #232c56;
      //margin-top: 4.5rem;
  

      @media (max-width: 800px) {
        max-height: 110px !important;
        margin-left:1rem;
      }
      @media (max-width: 600px) {
        max-height: 95px !important;
        margin-left:1rem;
      }
      @media (max-width: 400px) {
        max-height: 80px !important;
        margin-left:1rem;
      }


    }
  }
}


.scrolled-offset {
  margin-top: 70px;
}
