/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: $font-default;
  color: $default;
}

a {
  color: $primary;
  text-decoration: none;
}

a:hover  {
  color: darken($primary, 5);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-primary;
}

.textoBlanco {
  color: white !important;;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 85px;
  z-index: 996;
  background: $primary;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  transition: all 0.4s;

  i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }

  &:hover  {
    background: lighten($primary, 8);
    color: #fff;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

}
