/**
* Template Name: HeroBiz
* File: variables.css
* Description: Easily customize colors, typography, and other repetitive properties used in the template main stylesheet file main.css
*
* Template URL: https://bootstrapmade.com/herobiz-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*
* Contents:
* 1. HeroBiz template variables
* 2. Override default Bootstrap variables
* 3. Set color and background class names
*
* Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
*/

$primary: #232c56;
$secondary: #56a8da;
$font-family-base: 'Gotham', sans-serif;
$font-family-sans-serif: $font-family-base;
$font-primary: 'Gotham', sans-serif;
$font-secondary: 'Gotham', sans-serif;

$link-decoration: none;

:root {
    --font-primary: #{$font-primary};
    --font-secondary: #{$font-secondary};
}
